/**
 * These theme values will override the base theme.
 *
 * We're passing these values to Layout component;
 * The Layout component merges these values with the
 * base theme.
 *
 */

import theme from '@solid-ui-theme'
// import sizes
import sizes from '@solid-ui-theme/sizes'
import colors from '@solid-ui-theme/colors'

export default {
  ...theme,
  sizes: {
    ...sizes,
    container: 1400
  },
  colors: {
    background: `#f5f6f7`,
    headerBg: `transparent`,
    footerBg: `#f5f6f7`
  }
}
