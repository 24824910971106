import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import ModalPortfolio from '@solid-ui-blocks/Modal/Block03'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import LogosStrip from '@solid-ui-components/LogosStrip'
import Features from '@solid-ui-blocks/Features/Block05'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import WhyChooseUs from '@solid-ui-blocks/Features/Block01'
import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block06'
import FeatureThree from '@solid-ui-blocks/FeaturesWithPhoto/Block03'
import FeatureThreeMore from '@solid-ui-blocks/Features/Block06'
import Blog from '@solid-ui-blocks/Blog/Block01'
import Services from '@solid-ui-blocks/Services/Block01'
import Faq from '@solid-ui-blocks/Faq/Block01'
import Footer from '@solid-ui-blocks/Footer/Block02'
import Contact from '@solid-ui-blocks/CallToAction/Block02'
import Testimonials from '@solid-ui-blocks/Testimonials/Block01'
import ContentMap from '@solid-ui-components/ContentMap'

import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from '../_theme'
import styles from './_styles'

const PayOnlinePage = props => {
  const {
    allBlockContent,
    googlePlacesPlace: {
      rating,
      user_ratings_total,
      childrenGooglePlacesReview
    }
  } = props.data
  // console.log('childrenGooglePlacesReview: ', childrenGooglePlacesReview)
  // console.log('rating: ', rating)
  // console.log('user_ratings_total: ', user_ratings_total)
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  const heroData = props.data.allBlockContent.nodes.find(
    element => element.identifier === 'hero'
  )
  let thumbnail = heroData.images[0].src.childImageSharp.gatsbyImageData

  return (
    <Layout theme={theme} {...props}>
      <Seo
        title='Pay Online'
        indexed={false}
        thumbnail={thumbnail}
        keywords={[]}
      />
      {/* Modals */}
      {/* <ModalWithTabs content={content['authentication']} reverse /> */}
      <ModalWithTabs content={content['contact']} />
      {/* <ModalPortfolio content={content['physicians']} reverse /> */}
      {/* Blocks */}
      <Header content={content['header']} />

      <Container variant='full' sx={styles.heroContainer}>
        {/* <Divider space='4' />
        <Divider space='4' /> */}
        <Hero content={content['hero']} form={false} reverse />
      </Container>
      {/* <Container variant='full' sx={styles.insuranceContainer}>
        <LogosStrip content={content['insurance']} />
      </Container> */}
      {/* <Divider space='4' /> */}
      <Divider space='4' />
      <Divider space='4' />
      <Container sx={styles.featureOneContainer}>
        <FeatureOne content={content['feature-one']} reverse />
      </Container>
      <Divider space='4' />
      <Divider space='4' />

      <Container variant='full'>
        <Contact content={content['pricing-and-insurance']} />
      </Container>

      <Divider space='4' />
      <Divider space='4' />
      <div style={{ width: `100%`, height: `600px` }}>
        <ContentMap
          content={{
            lat: 42.0985394,
            lng: -87.9600665,
            zoom: 14
          }}
        />
      </div>

      <Divider space='4' />
      <Divider space='4' />
      {/* <Container variant='wide' sx={styles.whyChooseUsContainer}>
        <WhyChooseUs content={content['why-choose-us']} />
      </Container>
      <Divider space='4' />
      <Divider space='4' /> */}
      {/* <Container variant='wide' sx={styles.featureTwoContainer}>
        <FeatureTwo content={content['feature-two']} reverse />
      </Container>
      <Divider space='4' />
      <Divider space='4' /> */}
      {/* <Container variant='full' sx={styles.featureThreeContainer}>
        <FeatureThree content={content['feature-three']} />
        <Divider space='4' />
        <FeatureThreeMore content={content['feature-three-more']} />
      </Container> */}

      <Footer content={content['footer2']} />
    </Layout>
  )
}

export const query = graphql`
  query payOnlineRandBlockContent {
    allBlockContent(filter: { page: { in: ["site/pay-online", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
    googlePlacesPlace {
      name
      rating
      childrenGooglePlacesReview {
        author_name
        text
        rating
        profile_photo_url
      }
      user_ratings_total
    }
  }
`
export default PayOnlinePage
