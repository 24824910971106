/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import holographic from '../assets/holographic.jpg'
import homepageHero from '../../../content/assets/flexiblocks/shared/pay-online.jpeg'
import sprinkle from '../assets/sprinkle-pattern.svg'

export default {
  heroContainer: {
    position: `relative`,
    // bg: `#F6F7FA`,
    py: [6, 7],
    '::before, ::after': {
      position: `absolute`,
      content: `" "`,
      width: `full`,
      // height: `85%`,
      // top: [`25%`, null, null, `50%`],
      // right: `50%`,
      height: `100%`,

      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      // transform: `translate(50%, -50%)`,
      zIndex: -1
      // borderRadius: `0`
    },
    '::after': {
      background: `linear-gradient(
          180deg,
          #F6F7FA 0%,
          rgba(255,255,255,0.3) 40%,
          rgba(255,255,255,0.3) 70%,
          #e2e8f0 100%
        )`
    },
    '::before': {
      background: `url(${homepageHero}) no-repeat center center`,
      backgroundSize: `cover`
    }
    // '::before': {
    //   zIndex: -3,
    //   bg: `white`,
    //   background: t => `linear-gradient(
    //     145deg,
    //     ${t.colors.alpha} 0%,
    //     ${t.colors.alphaDarker} 100%
    //   )`
    // },
    // '::after': {
    //   zIndex: -2,
    //   background: `url(${homepageHero}) repeat right top`,
    //   opacity: 0.3
    // }
  },
  servicesContainer: {
    px: [3, 4]
  },
  insuranceContainer: {
    bg: `omegaLight`,
    py: 4,
    px: [4, 0],
    borderTop: '2px solid #c4cfdf'
  },
  featureOneContainer: {
    position: `relative`,
    py: [3],
    '::before, ::after': {
      position: `absolute`,
      content: `" "`,
      size: `70vw`,
      maxWidth: `500px`,
      maxHeight: `500px`,
      top: `50%`,
      left: `0%`,
      transform: [`translate(15vw, -185%)`, `translate(20%, -85%)`],
      zIndex: -2,
      borderRadius: `0`
    }
    // '::after': {
    //   background: `linear-gradient(
    //       180deg,
    //       rgba(255,255,255,0.2) 0%,
    //       rgba(255,255,255,0.9) 100%
    //     )`
    // }
    // '::before': {
    //   background: `url(${holographic}) no-repeat center center`,
    //   backgroundSize: `cover`
    // }
  },
  featureTwoContainer: {
    position: `relative`,
    py: [3],
    '::before, ::after': {
      position: `absolute`,
      content: `" "`,
      size: `70vw`,
      maxWidth: `500px`,
      maxHeight: `500px`,
      top: `50%`,
      right: `0%`,
      transform: [`translate(-15vw, 30%)`, `translate(-15%, -85%)`],
      zIndex: -2,
      borderRadius: `0`
    }
    // '::after': {
    //   background: `linear-gradient(
    //       180deg,
    //       rgba(255,255,255,0.2) 0%,
    //       rgba(255,255,255,0.9) 100%
    //     )`
    // },
    // '::before': {
    //   background: `url(${holographic}) no-repeat center center`,
    //   backgroundSize: `cover`
    // }
  },
  featureThreeContainer: {
    position: `relative`,
    py: [4],
    '::before, ::after': {
      position: `absolute`,
      content: `" "`,
      size: `full`,
      top: `30%`,
      right: `0%`,
      zIndex: -1
    }
    // '::after': {
    //   background: `linear-gradient(
    //       180deg,
    //       rgba(255,255,255,1) 0%,
    //       rgba(255,255,255,0.6) 50%,
    //       rgba(255,255,255,1) 100%
    //     )`
    // },
    // '::before': {
    //   background: `url(${holographic}) no-repeat center center`,
    //   backgroundSize: `cover`
    // }
  },
  whyChooseUsContainer: {
    bg: `omegaDarker`,
    borderRadius: `0`,
    py: 5,
    px: [4, 0]
  }
}
